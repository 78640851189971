import React from 'react';
import Widget from '../verticalWidget2';

const CategoryWidget15 = props => {
  return (
    <div>
      <Widget cardType={2} {...props} />
    </div>
  );
};

export default CategoryWidget15;
