import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Card11, Card12, Card13 } from './cards';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';
import { getRoute, PATH } from '@/utils/routes';
import { deviceWidth } from '@/utils/deviceWidth';
import { useSSRSelector } from '@/redux/ssrStore';

const CategoryWidget = (props) => {
  const router = useRouter();
  const [widgetConfig, setWidgetConfig] = useState(null);

  const { catalogCategories, storeData } = useSSRSelector((state) => ({
    catalogCategories: state.catalogReducer.categories,
    storeData: state.storeReducer.store,
  }));

  const viewMoreBtnClick = () => {
    router.push(getRoute(`${PATH.CATEGORIES}`, storeData?.store_info?.domain));
  };

  const getNumOfCatItemsVisible = () => {
    if (deviceWidth <= 767) {
      return 8;
    } else {
      return 14;
    }
  };

  /**
   * showSeeMoreBtn comes in as prop and is used to conditionally render
   * the max amount of category items. In /categories page, there is no max limit on
   * the items that can be rendered at a time.
   */
  useEffect(() => {
    if (props?.config && Object.keys(props.config)) {
      setWidgetConfig(props.config);
    }
  }, [props.config]);

  /**
   * conditionally render show more/less btn
   * @return {*} boolean
   */
  const renderShowBtn = () => {
    if (router.pathname === '/categories') return false;
    else {
      if (deviceWidth <= 767) {
        return catalogCategories?.length > 8;
      } else {
        return catalogCategories?.length > 14;
      }
    }
  };

  const onCategoryItemClick = (category) => {
    if (isPLPRequiredSystem(storeData?.theme?.theme_class)) {
      router.push(
        getRoute(`${PATH.SHOP}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    } else {
      router.push(
        getRoute(`${PATH.PRODUCT}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    }
  };

  const _renderCategoryCard = (category, idx, cardNumber) => {
    switch (cardNumber) {
      case 1:
        return (
          <Card11
            category={category}
            key={idx}
            widgetConfig={widgetConfig}
            onClick={onCategoryItemClick}
          />
        );
      case 2:
        return (
          <Card12
            category={category}
            key={idx}
            widgetConfig={widgetConfig}
            onClick={onCategoryItemClick}
          />
        );
      case 3:
        return (
          <Card13
            category={category}
            key={idx}
            widgetConfig={widgetConfig}
            onClick={onCategoryItemClick}
          />
        );
      default:
        return (
          <Card11
            category={category}
            key={idx}
            widgetConfig={widgetConfig}
            onClick={onCategoryItemClick}
          />
        );
    }
  };

  /**
   * max columns allowed to render is 7 (or 4 if mobile),
   * otherwise it renders the length of categories
   * @return {string} css attribute
   */
  const getTemplateColumns = () => {
    const categoryLength = catalogCategories?.length;
    const columnNumber = deviceWidth <= 766 ? 4 : 7;
    return `repeat(${
      categoryLength > columnNumber ? columnNumber : catalogCategories?.length
    }, minmax(50px, 1fr))`;
  };

  /**
   * return categories based on path
   * if pathname === "/categories", return all categories
   * otherwise return limited list of categories
   * @return {*} categories list
   */
  const catalogList = () => {
    if (router.pathname === '/categories') {
      return catalogCategories;
    } else {
      return catalogCategories?.slice(0, getNumOfCatItemsVisible());
    }
  };

  return (
    <div className="vertical-categoryWidget2 flex justify-center items-center">
      {widgetConfig && (
        <div className="vertical-categoryWidget2__container">
          <p className="vertical-categoryWidget2__title tc fw6">
            {widgetConfig?.heading}
          </p>
          <section
            className="vertical-categoryWidget2__box"
            style={{
              gridTemplateColumns: getTemplateColumns(),
            }}
          >
            {catalogList()?.length > 0 &&
              catalogList()?.map((category, index) => {
                return _renderCategoryCard(category, index, props.cardType);
              })}
          </section>
          {renderShowBtn() && (
            <section className="flex justify-center items-center expandable-btn--container">
              <button onClick={viewMoreBtnClick} className="expandable-btn">
                <span className="w-100 tc fw6">View More</span>
              </button>
            </section>
          )}
        </div>
      )}
    </div>
  );
};

export default CategoryWidget;
